import React from "react";
import { Link } from "gatsby";
import loadable from "@loadable/component";
import { Container, Row, Col } from "react-bootstrap";
import { filterCustomStyles } from "../components/DropdownSelectSettings/DropdownSelectSettings";
import sortByOptions from "../search_config/sort_options.json";
import LayoutTwo from "../components/layoutTwo";

import PropertyImg1 from "../images/properties_img_1.png";
import PropertyImg2 from "../images/properties_img_2.png";
import PropertyImg3 from "../images/properties_img_3.png";
import PropertyImg4 from "../images/properties_img_4.png";
import PropertyImg5 from "../images/properties_img_5.png";
import PropertyImg6 from "../images/properties_img_6.png";

const Select = loadable(() => import("react-select"));
const FilterSearch = loadable(() => import("../components/FilterSearch/FilterSearch"));
const SearchResultsHeading = loadable(() => import("../components/SearchResultsHeading/SearchResultsHeading"));
const NewHomesCard = loadable(() => import("../components/NewHomesCard/NewHomesCard"));
const SearchResultsPagination = loadable(() => import("../components/SearchResultsPagination/SearchResultsPagination"));

const NewHomeResults = () => {

    const allNewhomes = [
        {
            image: PropertyImg1,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        },
        {
            image: PropertyImg2,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        },
        {
            image: PropertyImg3,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        },
        {
            image: PropertyImg4,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        },
        {
            image: PropertyImg5,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        },
        {
            image: PropertyImg6,
            displayaddress: "St Leonards Square, Thrybergh S65",
            price: "Price from £325,000 – £650,000",
            bedroom: "2-4 bedroom houses for sale",
            desc: "<p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.</p> <p>You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances.</p>"
        }
    ]

    return (
        <LayoutTwo
            layout={"sticky-module"}
        >
            <div className="layout-padding-top"></div>

            <FilterSearch />

            <div className="search-results-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <SearchResultsHeading />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="d-md-flex align-items-center d-none">
                            <div className="search-results-count">135 Properties</div>
                        </Col>
                        <Col md={7}>
                            <ul className="list-inline search-results-list d-flex justify-content-md-end align-items-center">
                                <li className="list-inline-item d-md-none d-block">
                                    <div className="search-results-count">135 Properties</div>
                                </li>

                                <li className="list-inline-item d-md-none d-block">
                                    <div className="search-divider d-xl-block d-md-none"></div>
                                </li>
                                
                                <li className="list-inline-item">
                                    <div className="dropdown-select d-flex align-items-center search-results-sort">
                                        <span>Sort:</span>
                                        <Select
                                            options={sortByOptions}
                                            isSearchable={false}
                                            // defaultValue={sortby_options[sortby_key]}
                                            // value={sortby_options.value}
                                            placeholder={"Most Recent"}
                                            // onChange={changesortby}
                                            className={"select-control"}
                                            classNamePrefix={"react-select"}
                                            styles={filterCustomStyles}
                                            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown-primary"></i>, IndicatorSeparator:() => null }}
                                        />
                                    </div>
                                </li>

                                <li className="list-inline-item">
                                    <div className="search-divider d-xl-block d-md-none"></div>
                                </li>

                                <li className="list-inline-item">
                                    <div className="map-link">
                                        <Link to={"#"}  className="d-flex align-items-center">Map<span className="d-md-inline-block d-none ps-1">View</span> <i className="icon icon-map"></i></Link>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <div className="new-homes-card-wrapper-main">
                        {
                            allNewhomes.map((newHomes, i) => {
                                return (
                                    <NewHomesCard newHomesDetail={newHomes} key={i} />
                                )
                            })
                        }

                        <SearchResultsPagination />
                    </div>
                </Container>
            </div>
        </LayoutTwo>
    )
}

export default NewHomeResults